import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import useBaseUtils from "~/functions/useBaseUtils";
import { roundUpto } from "~/utils/constants/numbers";
import { createCurrencyFormatter } from "~/utils/valuationTransform/peModel";
export default {
  __name: 'ResultsHeader',
  props: {
    realizedAtDateObj: {
      type: Object,
      "default": function _default() {}
    },
    differenceInYears: {
      type: String,
      "default": ""
    },
    realizedAtDate: {
      type: String,
      "default": ""
    },
    stockPrice: {
      type: Object,
      "default": function _default() {}
    },
    smaller: {
      type: Boolean,
      "default": false
    },
    irr: {
      type: Object,
      "default": function _default() {}
    },
    totalStockPrice: {
      type: Object,
      "default": function _default() {}
    },
    totalReturn: {
      type: Object,
      "default": function _default() {}
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var formatValidCurrency = function formatValidCurrency(val, iso) {
      var digits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
      var formatter = createCurrencyFormatter({
        minDigits: digits,
        maxDigits: digits,
        isocode: iso
      });
      return formatter.format(val);
    };
    var formatWithCurrency = function formatWithCurrency(stockPriceObj) {
      var val = stockPriceObj === null || stockPriceObj === void 0 ? void 0 : stockPriceObj.v;
      if (!val) {
        return "";
      }
      var iso = stockPriceObj === null || stockPriceObj === void 0 ? void 0 : stockPriceObj.iso;
      var digits = 2;
      if (!iso) {
        return val.toString(digits);
      }
      return formatValidCurrency(val, iso);
    };
    var formattedStockPrice = computed(function () {
      return formatWithCurrency(props === null || props === void 0 ? void 0 : props.stockPrice);
    });
    var formattedReturnPerYear = computed(function () {
      var _props$irr;
      return "".concat(roundUpto(props === null || props === void 0 || (_props$irr = props.irr) === null || _props$irr === void 0 ? void 0 : _props$irr.v, 2), "%");
    });
    var formattedTotalStockPrice = computed(function () {
      return formatWithCurrency(props === null || props === void 0 ? void 0 : props.totalStockPrice);
    });
    var potentialPercentageFormatted = computed(function () {
      var _props$totalReturn;
      var value = (props === null || props === void 0 || (_props$totalReturn = props.totalReturn) === null || _props$totalReturn === void 0 ? void 0 : _props$totalReturn.v) * 100;
      var rounded = roundUpto(value, 2);
      return "".concat(rounded, "%");
    });
    return {
      __sfc: true,
      props: props,
      I18nFn: I18nFn,
      formatValidCurrency: formatValidCurrency,
      formatWithCurrency: formatWithCurrency,
      formattedStockPrice: formattedStockPrice,
      formattedReturnPerYear: formattedReturnPerYear,
      formattedTotalStockPrice: formattedTotalStockPrice,
      potentialPercentageFormatted: potentialPercentageFormatted
    };
  }
};