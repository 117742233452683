import ResultsChart from "./ResultsChart.vue"
import ResultsHeader from "./ResultsHeader.vue"
import ResultsMessage from "./ResultsMessage.vue"
import SimpleCompanyMeta from "~/components/valuationModels/SimpleCompanyMeta.vue"
import useBaseUtils from "~/functions/useBaseUtils"


export default {
  __name: 'ShareArea',
  props: {
  headerProps: {
    type: Object,
    required: true,
    default: () => {},
  },
  messageProps: {
    type: Object,
    required: true,
    default: () => {},
  },
  chartOptions: {
    type: Object,
    required: true,
    default: () => {},
  },
},
  setup(__props) {



const { I18nFn } = useBaseUtils()

return { __sfc: true,I18nFn, ResultsChart, ResultsHeader, ResultsMessage, SimpleCompanyMeta }
}

}