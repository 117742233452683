var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"results-header",class:{
    'results-header-smaller': _vm.smaller,
    'py-6': !_vm.smaller,
    'py-4': _vm.smaller,
    'px-6': true,
    'px-md-10': !_vm.smaller,
    'px-md-8': _vm.smaller,
  }},[_c('p',[_vm._v(_vm._s(_setup.I18nFn("Based on your assumptions"))+":")]),_vm._v(" "),_c('p',{staticClass:"mb-0 results-header-highlight",class:{
      'results-header-highlight-smaller': _vm.smaller,
    }},[_vm._v("\n    "+_vm._s(_setup.I18nFn("Purchasing the stock at the current stock price of"))+"\n    "),_c('span',[_vm._v(_vm._s(_setup.formattedStockPrice))]),_vm._v("\n    "+_vm._s(_setup.I18nFn("gives you the potential to make a total return of"))+"\n    "),_c('span',{class:{ 'red--text': _vm.totalReturn?.v < 0 }},[_vm._v("\n      "+_vm._s(_setup.potentialPercentageFormatted)+"\n    ")]),_vm._v("\n    "+_vm._s(_setup.I18nFn("over the next"))+"\n    "+_vm._s(_vm.differenceInYears)+"\n    "+_vm._s(_setup.I18nFn("years"))+".\n    "+_vm._s(_setup.I18nFn("This is a"))+"\n    "),_c('span',{class:{ 'red--text': _vm.irr?.v < 0 }},[_vm._v("\n      "+_vm._s(_setup.formattedReturnPerYear)+"\n    ")]),_vm._v("\n    "+_vm._s(_setup.I18nFn("return per year based on your target total stock price of"))+"\n    "),_c('span',[_vm._v(_vm._s(_setup.formattedTotalStockPrice))]),_vm._v(" "),_c('small',{staticClass:"d-block mt-1"},[_vm._v("\n      ("+_vm._s(_setup.I18nFn("Realized at"))+": "+_vm._s(_vm.realizedAtDateObj?.fiscalDate)+")\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }