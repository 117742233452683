export default {
  __name: 'ResultsChart',
  props: {
  chartOptions: {
    type: Object,
    required: true,
    default: () => {},
  },
},
  setup(__props) {



return { __sfc: true, }
}

}